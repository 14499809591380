import { render, staticRenderFns } from "./Reviews2.vue?vue&type=template&id=7d68af7a&scoped=true&"
import script from "./Reviews2.vue?vue&type=script&lang=ts&"
export * from "./Reviews2.vue?vue&type=script&lang=ts&"
import style0 from "./Reviews2.vue?vue&type=style&index=0&id=7d68af7a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d68af7a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VImg,VRow})
